var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "block" }, [
      _c("div", { staticClass: "columns team-member-properties" }, [
        _c(
          "div",
          { staticClass: "column ", staticStyle: { "padding-left": "10px" } },
          [
            _c("div", { staticClass: "field" }, [
              _vm._m(1),
              _c("div", { staticClass: "control" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.input.displayName,
                      expression: "input.displayName",
                    },
                  ],
                  staticClass: "input",
                  class: {
                    input: true,
                    "is-danger": _vm._errors.displayName !== undefined,
                  },
                  attrs: { type: "text", name: "displayName" },
                  domProps: { value: _vm.input.displayName },
                  on: {
                    blur: _vm.updateTeamInfo,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.input, "displayName", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "field is-grouped" }, [
              _c("div", { staticClass: "field" }, [
                _c("label", { staticClass: "label is-size-7" }, [
                  _vm._v("Title"),
                ]),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.input.title,
                        expression: "input.title",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "text", name: "team_member_title" },
                    domProps: { value: _vm.input.title },
                    on: {
                      blur: _vm.updateTeamInfo,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.input, "title", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("div", { staticClass: "field" }, [
                _vm._m(2),
                _c("div", { staticClass: "control" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.input.phoneNumber,
                        expression: "input.phoneNumber",
                      },
                    ],
                    staticClass: "input",
                    class: {
                      input: true,
                      "is-danger": _vm._errors.phoneNumber !== undefined,
                    },
                    attrs: { type: "text", name: "team_member_phone_number" },
                    domProps: { value: _vm.input.phoneNumber },
                    on: {
                      blur: _vm.updateTeamInfo,
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.input, "phoneNumber", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]
        ),
        _c("div", { staticClass: "column" }, [
          _c("label", { staticClass: "label is-size-7" }, [_vm._v("Image")]),
          _c(
            "div",
            {
              staticClass: "field is-grouped is-image",
              staticStyle: { cursor: "pointer" },
            },
            [
              _c("div", { staticClass: "field" }, [
                _c(
                  "div",
                  {
                    staticClass: "box has-background-light",
                    staticStyle: { padding: "10px", width: "150px" },
                    on: {
                      click: function ($event) {
                        return _vm.element_clicker("#file")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "image" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.input.avatarPath.includes("blob:")
                            ? _vm.input.avatarPath
                            : _vm.input.avatarPath +
                              "?ref=" +
                              new Date().getTime(),
                        },
                      }),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "field",
                  staticStyle: { "padding-left": "20px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "field",
                      staticStyle: { cursor: "pointer" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        class: ["button is-accent"],
                        attrs: {
                          disabled: !_vm.$hasPermissions(
                            _vm.clientSession,
                            ["USERS"],
                            2
                          ),
                          id: "file",
                          type: "file",
                          name: "pic",
                          accept: "image/*",
                        },
                        on: { change: _vm.onAvatarChange },
                      }),
                      _c(
                        "label",
                        {
                          staticClass: "button is-accent",
                          class: [
                            !_vm.$hasPermissions(
                              _vm.clientSession,
                              ["USERS"],
                              2
                            )
                              ? "disabled"
                              : "",
                          ],
                          attrs: { for: "file" },
                        },
                        [_vm._v("Replace")]
                      ),
                    ]
                  ),
                  _c(
                    "label",
                    { staticClass: "label is-size-7 has-text-warning" },
                    [_vm._v("Supports GIF, PNG, JPG, and JPEG")]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "block" }, [
      _c("div", { staticClass: "field" }, [
        _c(
          "label",
          {
            staticClass: "label is-size-7",
            staticStyle: { display: "block", top: "33px" },
          },
          [_vm._v("Bio")]
        ),
        _c("div", { staticClass: "control" }, [
          _c("div", [
            _c(
              "div",
              { staticClass: "content-tab", staticStyle: { display: "block" } },
              [
                _c("tinymce", {
                  ref: "editor",
                  attrs: { id: "editor", plugins: "code", toolbar: "code" },
                  on: { input: _vm.updateTeamInfo },
                  model: {
                    value: _vm.input.bio,
                    callback: function ($$v) {
                      _vm.$set(_vm.input, "bio", $$v)
                    },
                    expression: "input.bio",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Team Member Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Display Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Phone Number "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }