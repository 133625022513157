var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white is-uppercase",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c("div", { attrs: { id: "body-content-area" } }, [
              _c("div", { attrs: { id: "pagelayout" } }, [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.handleSubmitLocal("/users", {
                          name: "Users",
                        })
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "button-group" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-accent has-text-white",
                          attrs: {
                            disabled:
                              !_vm.$hasPermissions(
                                _vm.clientSession,
                                ["USERS"],
                                2
                              ) || _vm.emailExists,
                            type: "submit",
                          },
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button is-light",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              _vm.resetForm()
                              _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          class: _vm.isCreateMode ? "is-hidden" : "is-accent",
                          attrs: {
                            disabled:
                              _vm.isCreateMode ||
                              !_vm.$hasPermissions(
                                _vm.clientSession,
                                ["USERS"],
                                2
                              ),
                          },
                          on: {
                            click: function ($event) {
                              _vm.isCreateMode
                                ? "do nothing"
                                : _vm.input.isLockedOut
                                ? _vm.unlock()
                                : _vm.lock()
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.input.isLockedOut
                                ? "Unlock This Acct"
                                : "Lock This Acct"
                            )
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "columns is-clearfix" }, [
                      _c("div", { staticClass: "column is-one-third" }, [
                        _vm._m(0),
                        _c("div", { staticClass: "block" }, [
                          _c("div", [
                            _c("div", { staticClass: "field is-grouped" }, [
                              _c("div", { staticClass: "control" }, [
                                _vm._m(1),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.firstName,
                                      expression: "input.firstName",
                                    },
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger":
                                      _vm.errors.lastName !== undefined,
                                  },
                                  attrs: { type: "text", name: "firstName" },
                                  domProps: { value: _vm.input.firstName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "firstName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _c("div", { staticClass: "control" }, [
                                _vm._m(2),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.lastName,
                                      expression: "input.lastName",
                                    },
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger":
                                      _vm.errors.firstName !== undefined,
                                  },
                                  attrs: { type: "text", name: "lastName" },
                                  domProps: { value: _vm.input.lastName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "lastName",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(3),
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.input.email,
                                      expression: "input.email",
                                    },
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger":
                                      _vm.emailExists ||
                                      _vm.errors.email !== undefined,
                                  },
                                  attrs: {
                                    type: "text",
                                    name: "email",
                                    disabled: !_vm.isCreateMode,
                                  },
                                  domProps: { value: _vm.input.email },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.input,
                                        "email",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm.emailExists
                                ? _c(
                                    "label",
                                    {
                                      staticClass:
                                        "label is-size-7 has-text-danger",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.errors.email
                                              ? _vm.errors.email
                                              : _vm.emailExists
                                              ? "Email is already in use. "
                                              : ""
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "field" }, [
                              _vm._m(4),
                              _c("div", { staticClass: "control inline" }, [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      select: true,
                                      "is-danger":
                                        _vm.errors.role !== undefined,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.getActiveRole($event)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.input.role,
                                            expression: "input.role",
                                          },
                                        ],
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.input,
                                              "role",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(_vm.roles, function (role) {
                                        return _c(
                                          "option",
                                          {
                                            key: role,
                                            domProps: { value: role },
                                          },
                                          [_vm._v(_vm._s(role))]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                              _vm.input.role != "Client" && _vm.input.role != ""
                                ? _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.input.isTeamMember,
                                          expression: "input.isTeamMember",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: "isTeamMember",
                                        name: "isTeamMember",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.input.isTeamMember
                                        )
                                          ? _vm._i(
                                              _vm.input.isTeamMember,
                                              null
                                            ) > -1
                                          : _vm.input.isTeamMember,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.input.isTeamMember =
                                            !_vm.input.isTeamMember
                                        },
                                        change: function ($event) {
                                          var $$a = _vm.input.isTeamMember,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.input,
                                                  "isTeamMember",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.input,
                                                  "isTeamMember",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.input,
                                              "isTeamMember",
                                              $$c
                                            )
                                          }
                                        },
                                      },
                                    }),
                                    _c(
                                      "label",
                                      {
                                        staticClass: "is-size-7",
                                        staticStyle: { padding: "0px 7px" },
                                        attrs: { for: "isTeamMember" },
                                      },
                                      [_vm._v("User is a Team Member")]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("br"),
                            ]),
                          ]),
                        ]),
                        !_vm.isCreateMode
                          ? _c("div", { staticClass: "block" }, [
                              _vm._m(5),
                              _c("div", { staticClass: "block" }, [
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "field is-grouped" },
                                    [
                                      _c("div", { staticClass: "control" }, [
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c(
                                              "b-radio",
                                              {
                                                attrs: {
                                                  "native-value": "resetEmail",
                                                  type: "is-accent",
                                                },
                                                model: {
                                                  value: _vm.passwordResetRadio,
                                                  callback: function ($$v) {
                                                    _vm.passwordResetRadio = $$v
                                                  },
                                                  expression:
                                                    "passwordResetRadio",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Send password reset email"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "field" },
                                          [
                                            _c(
                                              "b-radio",
                                              {
                                                attrs: {
                                                  "native-value": "setPassword",
                                                  type: "is-accent",
                                                },
                                                model: {
                                                  value: _vm.passwordResetRadio,
                                                  callback: function ($$v) {
                                                    _vm.passwordResetRadio = $$v
                                                  },
                                                  expression:
                                                    "passwordResetRadio",
                                                },
                                              },
                                              [_vm._v("Manually set password")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.passwordResetRadio ===
                                            "setPassword",
                                          expression:
                                            "passwordResetRadio === 'setPassword'",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "field",
                                          staticStyle: {
                                            "padding-left": "2rem",
                                          },
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "label is-size-7" },
                                            [_vm._v("New Password")]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "control" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.newPassword,
                                                    expression: "newPassword",
                                                  },
                                                ],
                                                staticClass: "input",
                                                attrs: {
                                                  type: "text",
                                                  name: "password",
                                                },
                                                domProps: {
                                                  value: _vm.newPassword,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.newPassword =
                                                      $event.target.value
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "is-size-7",
                                          staticStyle: {
                                            padding: "10px 0px 20px 40px",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.passwordValidation,
                                            function (item) {
                                              return _c(
                                                "div",
                                                {
                                                  key: item.msg + item.isValid,
                                                  staticStyle: {
                                                    "padding-left": "40px",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class: item.isValid
                                                      ? "far fa-check-circle has-text-accent"
                                                      : "fas fa-times has-text-danger",
                                                  }),
                                                  _vm._v(
                                                    " : " +
                                                      _vm._s(item.msg) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.forceChange,
                                                    expression: "forceChange",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  id: "forceChange",
                                                  name: "forceChange",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.forceChange
                                                  )
                                                    ? _vm._i(
                                                        _vm.forceChange,
                                                        null
                                                      ) > -1
                                                    : _vm.forceChange,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = _vm.forceChange,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          (_vm.forceChange =
                                                            $$a.concat([$$v]))
                                                      } else {
                                                        $$i > -1 &&
                                                          (_vm.forceChange = $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                      }
                                                    } else {
                                                      _vm.forceChange = $$c
                                                    }
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "is-size-7",
                                                  staticStyle: {
                                                    padding: "0px 7px",
                                                  },
                                                  attrs: { for: "forceChange" },
                                                },
                                                [
                                                  _vm._v(
                                                    "Force user to change password on next login"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "control buttons",
                                      staticStyle: {
                                        width: "100%",
                                        "margin-left": "2rem",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button",
                                          class: [
                                            _vm.isCreateMode
                                              ? "is-hidden"
                                              : "is-accent",
                                            !_vm.$hasPermissions(
                                              _vm.clientSession,
                                              ["USERS"],
                                              2
                                            )
                                              ? "disabled"
                                              : "",
                                          ],
                                          attrs: { disabled: _vm.isCreateMode },
                                          on: {
                                            click: function ($event) {
                                              _vm.isCreateMode
                                                ? "do nothing"
                                                : !_vm.$hasPermissions(
                                                    _vm.clientSession,
                                                    ["USERS"],
                                                    2
                                                  )
                                                ? null
                                                : _vm.passwordReset()
                                            },
                                          },
                                        },
                                        [_vm._v("Reset Password")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "button is-light",
                                          class: {
                                            hidden: _vm.isCreateMode,
                                            "is-loading":
                                              _vm.isDeletingMFAMethods,
                                            disabled: !_vm.$hasPermissions(
                                              _vm.clientSession,
                                              ["USERS"],
                                              2
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.isCreateMode
                                                ? "do nothing"
                                                : !_vm.$hasPermissions(
                                                    _vm.clientSession,
                                                    ["USERS"],
                                                    2
                                                  )
                                                ? null
                                                : _vm.mfaReset()
                                            },
                                          },
                                        },
                                        [
                                          _vm.hasResetMFA
                                            ? _c("i", {
                                                staticClass: "fa fa-check",
                                              })
                                            : _c("span", [_vm._v("Reset MFA")]),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "column" }, [
                        _vm.activeRole >= 0 && _vm.activeRole != 2
                          ? _c(
                              "div",
                              {
                                staticClass: "block",
                                staticStyle: {
                                  padding: "0px 0px 7px 0px",
                                  "border-bottom": "solid 1px lightgray",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "has-text-weight-bold" },
                                  [_vm._v("Role Properties")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "block" }, [
                          _vm.input.role === "Client"
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "40px" } },
                                [
                                  _c("Client", {
                                    attrs: {
                                      model: this.membershipInput,
                                      errors: _vm.errors,
                                    },
                                    on: {
                                      membershipUpdated:
                                        _vm.onMembershipUpdated,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          ["Staff", "Manager"].includes(_vm.input.role) &&
                          _vm.$hasPermissions(
                            _vm.clientSession,
                            ["PERMISSION_TEMPLATES", "USERS"],
                            1
                          )
                            ? _c("div", [
                                _vm._m(6),
                                _c(
                                  "div",
                                  {
                                    staticClass: "block",
                                    staticStyle: { "margin-bottom": "3rem" },
                                  },
                                  [
                                    _c("div", { staticClass: "field" }, [
                                      _vm._m(7),
                                      _c(
                                        "div",
                                        { staticClass: "control inline" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("searchable-select", {
                                                attrs: {
                                                  disabled:
                                                    _vm.clientSession.userId ===
                                                    _vm.input.id,
                                                  options:
                                                    _vm.permissionTemplateOptions,
                                                  selected:
                                                    _vm.input.permissions,
                                                  multiple: true,
                                                  searchable: true,
                                                  clearable: true,
                                                  placeholder:
                                                    "Select Permissions",
                                                  isDefault: false,
                                                },
                                                on: {
                                                  selected:
                                                    _vm.onPermissionsChanged,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "table",
                                      {
                                        staticClass: "table table-striped",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _vm._m(8),
                                        _vm._l(
                                          _vm.permissions,
                                          function (permission) {
                                            return _c(
                                              "tr",
                                              {
                                                key: permission.id,
                                                staticClass: "field",
                                              },
                                              [
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      color:
                                                        "#454545!important",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          permission.name
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        permission.access = 0
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      ref: permission.code,
                                                      refInFor: true,
                                                      attrs: {
                                                        disabled:
                                                          _vm.clientSession
                                                            .userId ===
                                                          _vm.input.id,
                                                        type: "radio",
                                                        name: permission.code,
                                                      },
                                                      domProps: {
                                                        value: 0,
                                                        checked:
                                                          permission.access ===
                                                          0,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        permission.access = 1
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      ref: permission.code,
                                                      refInFor: true,
                                                      attrs: {
                                                        disabled:
                                                          _vm.clientSession
                                                            .userId ===
                                                          _vm.input.id,
                                                        type: "radio",
                                                        name: permission.code,
                                                      },
                                                      domProps: {
                                                        value: 1,
                                                        checked:
                                                          permission.access ===
                                                          1,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "td",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        permission.access = 2
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("input", {
                                                      ref: permission.code,
                                                      refInFor: true,
                                                      attrs: {
                                                        disabled:
                                                          _vm.clientSession
                                                            .userId ===
                                                          _vm.input.id,
                                                        type: "radio",
                                                        name: permission.code,
                                                      },
                                                      domProps: {
                                                        value: 2,
                                                        checked:
                                                          permission.access ===
                                                          2,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.input.role === "Outside Sales Rep"
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "40px" } },
                                [
                                  _c("OutsideSalesRep", {
                                    attrs: {
                                      model: this.membershipInput,
                                      _errors: _vm.errors,
                                    },
                                    on: {
                                      membershipUpdated:
                                        _vm.onMembershipUpdated,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.input.isTeamMember
                            ? _c(
                                "div",
                                [
                                  _c("TeamMember", {
                                    attrs: {
                                      model: this.inputRo,
                                      _errors: _vm.errors,
                                    },
                                    on: {
                                      teamInfoUpdated: _vm.onTeamInfoUpdate,
                                      avatarChange: _vm.onAvatarChange,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("User Properties"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" First Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Last Name "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Email "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Role "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Password Reset"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Permissions"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Default Permission Template "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticStyle: { width: "calc(100% - 30%)" } }, [
        _vm._v("Section"),
      ]),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("None")]),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Read")]),
      _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Read/Write")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }